import React, { type DetailedHTMLProps, type VideoHTMLAttributes, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BiChevronLeftCircle, BiChevronRightCircle, BiX, FiPlayCircle } from "@acme/ui";
import { FooterSite, HeaderSite, headerVariants } from "@acme/feature";
import Head from "next/head";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

const LandingPage = () => {
  return (
    <>
      <Head>
        <title>DapperGPT - Your Better UI for ChatGPT</title>
      </Head>
      <div className="flex flex-col min-h-screen">
        {/* Floating header */}
        <TopPart />
        {/* <VideoSection /> */}
        <FaqSection />
        {/* <BlogSection /> */}
        <FooterSite />
      </div>
    </>
  );
};

type Feature = {
  title: string;
  description: string;
  video: string;
  image?: string
}

const features: Feature[] = [
  {
    title: 'Better Chat',
    description: 'Easily customize prompts, systems, and more for a personalized experience.',
    video: '/landing/feature-1-chat.mp4',
  },
  {
    title: 'AI-Powered Notes',
    description: 'Leverage AI to draft and autocomplete notes, making note-taking a breeze.',
    video: '/landing/DapperGPT - Note Intro.mp4',
  },
  {
    title: 'AI-Powered Chrome Extension',
    description: 'Use our browser extension to enjoy autocomplete functionality on popular websites.',
    video: '/landing/feature-3-extension.mp4',
    image: '/landing/feature-3-extension.gif',
  },
  {
    title: 'Smart Search',
    description: 'Search your notes and chat history with ease.',
    video: '/landing/DapperGPT - Search Intro.mp4',
  },
  {
    title: 'Pin Favorite',
    description: 'Pin your favorite notes and chat sessions for easy access.',
    video: '',
    image: '/landing/subfeature-favorite.gif',
  },
  {
    title: 'Sync',
    description: 'Sync your notes and chat sessions across all your devices.',
    video: '',
    image: '/landing/subfeature-cloud.gif',
  },
  {
    title: 'Image Generate',
    description: 'Generate AI images directly in chat with ease.',
    video: '',
    image: '/landing/subfeature-imagegen.gif',
  },
  {
    title: 'Character Instruction',
    description: 'Select or set your own character instruction to make your chat response more personalized.',
    video: '',
    image: '/landing/subfeature-imagegen.gif',
  },
  {
    title: 'Prompt Library',
    description: 'Use our prompt library to quickly generate prompts for your chat.',
    video: '',
    image: '/landing/subfeature-prompt.gif',
  },
  {
    title: 'Code Generation',
    description: 'Export your chat session to code for easy integration into your own projects.',
    video: '',
    image: '/landing/subfeature-code.gif',
  },
  {
    title: 'Voice',
    description: 'Use our voice feature to speak to your AI or have your AI speak to you.',
    video: '',
    image: '/landing/subfeature-voice.gif',
  },
  {
    title: 'Many More Features',
    description: 'Enjoy a dark mode experience, AI Instruction List and Prompt List that you can edit, code export and more!',
    video: '',
    image: '/landing/other.png',
  },
];

const TopPart: React.FC = () => {
  const router = useRouter()
  const { data: session, status } = useSession()
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
  const [featureIndex, setFeatureIndex] = useState(-1);

  const handleFeatureClick = (feat: Feature, index: number) => {
    setSelectedFeature(feat);
    setFeatureIndex(index);
  };

  const handleCloseVideo = () => {
    setSelectedFeature(null);
    setFeatureIndex(-1);
  };

  const handlePrevFeature = () => {
    if (featureIndex > 0) {
      const feat = features[featureIndex - 1]
      if (feat)
        handleFeatureClick(feat, featureIndex - 1);
    }
  };

  const handleNextFeature = () => {
    if (featureIndex < features.length - 1) {
      const feat = features[featureIndex + 1]
      if (feat)
        handleFeatureClick(feat, featureIndex + 1);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape" && selectedFeature) {
      handleCloseVideo();
    } else if (e.key === "ArrowLeft" && featureIndex > 0) {
      handlePrevFeature()
    } else if (e.key === "ArrowRight" && featureIndex < features.length - 1) {
      handleNextFeature()
    }
  };

  const handleScrollToVideo = () => {
    const video = document.getElementById('video-section')
    if (video) {
      video.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, featureIndex]);

  useEffect(() => {
    if (session) {
      if (status === "authenticated") {
        router.push('/chat')
      }
    }
  }, [session, status, router])

  return (
    <>
      <Head>
        <meta property="og:title" content="DapperGPT - Better UI for ChatGPT with Note and Chrome Extension" />
        <meta property="og:description" content="DapperGPT is a better UI for ChatGPT with Note and Chrome Extension" />
        <meta property="og:image" content="https://dappergpt.com/images/social-media-banner-compressed.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <div className="bg-gradient-to-r from-blue-800 to-blue-500 min-h-screen flex flex-col">
        <HeaderSite />
        <section className="container mx-auto px-4 py-16 pb-14 text-white flex-grow flex flex-col justify-center items-center">
          <motion.h1
            className="text-5xl font-bold mb-6"
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
          >
            A Better ChatGPT UI & Experience{/* Enhance Your ChatGPT Experience */}
          </motion.h1>
          <motion.h2
            className="text-2xl mb-12 max-w-2xl text-center"
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.7 }}
          >
            Discover a new way to interact with ChatGPT API through our intuitive interface and powerful features.
          </motion.h2>
          <div className="flex flex-row items-center gap-3">
            <motion.a
              href="/chat"
              className="bg-white px-10 py-4 w-[200px] text-xl text-center rounded font-semibold text-blue-800 hover:bg-blue-200 transition-all duration-300"
              variants={headerVariants}
              initial="hidden"
              animate="visible"
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.9 }}
            >
              Try Now
            </motion.a>
            {/* <motion.button
              onClick={handleScrollToVideo}
              className="bg-transparent px-10 py-4 w-[230px] text-xl text-center rounded font-semibold text-blue-100 hover:bg-blue-400 hover:text-blue-700 transition-all duration-300"
              variants={headerVariants}
              initial="hidden"
              animate="visible"
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.9 }}
            >
              Video Preview
            </motion.button> */}
          </div>

          <motion.div className="mt-14 mb-10 max-w-3xl">
            <HoverVideoPlayer
              src="/landing/intro-fast.mp4"
              imagePreviewSrc="/landing/intro-fast.png"
              autoPlay
              loop
              muted
              className="w-full h-auto object-cover rounded-lg shadow-xl"
            />
          </motion.div>
          <div className="flex flex-wrap gap-6 justify-center mt-10">
            <motion.div>
              {/* <div className="flex flex-row gap-1 justify-center mb-4">
                <BiChevronLeftCircle className="text-3xl" />
                <BiChevronRightCircle className="text-3xl" />
              </div> */}
              <motion.div
                className="flex flex-row items-center justify-center gap-3"
                initial="hidden"
                animate="visible"
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.9 }}
              >
                <h3 className="text-3xl">Features</h3>
              </motion.div>
              <p className="my-4">See how DapperGPT can help you</p>
            </motion.div>
            <div className="flex flex-wrap gap-4 justify-center">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className={`px-3 py-3 w-[auto] cursor-pointer lg:w-[200px] text-center flex justify-center items-center rounded font-medium text-blue-200 bg-blue-900 transition-all duration-300 hover:bg-blue-300 hover:text-blue-900 border-2
                  ${(feature.video === selectedFeature?.video && index === featureIndex) ? '!border-blue-300 !bg-blue-300 !text-blue-900' : 'border-transparent'}`}
                  variants={headerVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.9 + index * 0.2 }}
                  onClick={() => handleFeatureClick(feature, index)}
                >
                  {/* {feature?.video ? (
                    <HoverVideoPlayer
                      className="w-full h-[200px] rounded-lg"
                      src={feature.video}
                    />
                  ) : (
                    <HoverGifPlayer
                      className="w-full h-[200px] rounded-lg object-cover"
                      src={feature.image}
                      alt={feature.title}
                    />
                  )} */}
                  <p className="mt-0 py-3">{feature.title}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
        {selectedFeature && (
          <AnimatePresence>
            <motion.div
              className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20 bg-black bg-opacity-50"
              onClick={handleCloseVideo}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            ></motion.div>
            <motion.div
              className="fixed top-[30vh] right-0 w-[550px] max-w-full p-1 lg:p-4 bg-transparent flex items-center justify-center z-30"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.5 }}
            >
              <div className="relative w-full h-auto max-h-full p-3 lg:p-5 bg-white rounded-lg shadow-lg">
                {selectedFeature?.image ? (
                  <img
                    src={selectedFeature.image}
                    alt={selectedFeature.title}
                    className="w-full h-[250px] object-contain max-h-full rounded"
                  />
                ) : selectedFeature?.video ? (
                  <video
                    src={selectedFeature.video}
                    controls={false}
                    autoPlay
                    muted
                    className="w-full h-auto max-h-full rounded"
                  />
                ) : null}
                <div className="flex flex-row items-center justify-between mt-3 gap-3">
                  <BiChevronLeftCircle
                    className="text-4xl cursor-pointer"
                    onClick={handlePrevFeature}
                  />
                  <div className="flex flex-row gap-2 text-center">
                    {selectedFeature?.description}
                  </div>
                  <BiChevronRightCircle
                    className="text-4xl cursor-pointer"
                    onClick={handleNextFeature}
                  />
                </div>
                <motion.button
                  className="absolute top-0 right-0 m-2 p-1 w-8 h-8 text-white bg-blue-900 rounded-full opacity-80 hover:opacity-100 focus:outline-none flex items-center justify-center"
                  onClick={handleCloseVideo}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <BiX />
                </motion.button>
              </div>
            </motion.div>
          </AnimatePresence>
        )}


        <style jsx>{`
        body {
          overscroll-behavior: none;
      }
      `}</style>
      </div>
    </>
  );
};

const VideoSection: React.FC = () => {
  const videoId = "C-IW-cxGKVE";

  return (
    <section className="bg-white text-gray-600 py-16" id="video-section">
      <div className="container mx-auto px-4">
        <h3 className="text-4xl font-bold mb-8">Discover DapperGPT in Action</h3>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-8">
          <div className="w-full lg:w-1/2">
            <div className="relative w-full h-0 pb-[56.25%]">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=0&showinfo=0&controls=1&modestbranding=1`}
                title="DapperGPT Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-xl">
              Watch our video to learn how DapperGPT can enhance your ChatGPT experience with its powerful features and intuitive interface.
            </p>
            <p className="mt-4">
              DapperGPT is currently in <span className="p-1 bg-white text-blue-700">beta</span>. We appreciate your patience as we continue to improve the product. If you encounter any issues or have suggestions, please let us know.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

/* Character Select
Prompt Library
Color Highlight
Code Highlight and Open to Codepen
Domain Check
Smart Search
Pin to Favorite
Text to Voice
Voice to Text
Generate Code
Generate Image */

const FaqSection = () => {
  return (
    <section className="bg-[#e4e4e5] text-gray-600 ">
      <div className="container mx-auto px-4 py-24">
        {/** <!-- Grid --> **/}
        <div className="flex flex-col items-center">
          <div className="mb-20">
            <div className="">
              <h2 className="text-2xl font-bold md:text-4xl dark:text-white">Frequently asked questions</h2>
              <p className="mt-1 hidden md:block text-gray-100 text-center">Answers to the most frequently asked questions,<br />need more help contact us to <a href="mailto:hi@dappergpt.com" className="underline text">hi@dappergpt.com</a></p>
            </div>
          </div>
          {/** <!-- End Col --> **/}

          <div className="max-w-3xl">
            {/** <!-- Accordion --> **/}
            <div className="hs-accordion-group divide-y-4 divide-gray-700">
              {faqList.map((faq, index) => (
                <div key={index}
                  className="hs-accordion hs-accordion-active:bg-gray-700 bg-gray-600 rounded-xl p-6 active mb-1"
                  id="hs-basic-with-title-and-arrow-stretched-heading-one"
                >
                  <button className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-100 transition hover:text-gray-200" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                    {faq.question}
                    <svg className="hs-accordion-active:hidden block w-3 h-3 text-gray-100 group-hover:text-gray-200 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                    <svg className="hs-accordion-active:block hidden w-3 h-3 text-gray-100 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </button>
                  <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                    <p className="text-gray-300 text-sm">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/** <!-- End Accordion --> **/}
          </div>
          {/** <!-- End Col --> **/}
        </div>
        {/** <!-- End Grid --> **/}
      </div>
      {/** <!-- End FAQ --> **/}
    </section>
  )
}

const BlogSection = () => {
  return (
    <section>
      {/** <!-- Card Blog  --> **/}
      <div className="container mx-auto px-4 py-20">
        {/** <!-- Title  --> **/}
        <div className="max-w-2xl text-center mx-auto mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Read our latest news</h2>
          <p className="mt-1 text-gray-600 dark:text-gray-400">We've helped some great companies brand, design and get to market.</p>
        </div>
        {/** <!-- End Title  --> **/}

        {/** <!-- Grid  --> **/}
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-10 lg:mb-14">
          {/** <!-- Card  --> **/}
          <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800" href="#">
            <div className="aspect-w-16 aspect-h-9">
              <img className="w-full object-cover rounded-t-xl" src="https://images.unsplash.com/photo-1668869713519-9bcbb0da7171?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80" alt="Image Description" />
            </div>
            <div className="p-4 md:p-5">
              <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
                Product
              </p>
              <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                Better is when everything works together
              </h3>
            </div>
          </a>
          {/** <!-- End Card  --> **/}

          {/** <!-- Card  --> **/}
          <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800" href="#">
            <div className="aspect-w-16 aspect-h-9">
              <img className="w-full object-cover rounded-t-xl" src="https://images.unsplash.com/photo-1668584054035-f5ba7d426401?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3465&q=80" alt="Image Description" />
            </div>
            <div className="p-4 md:p-5">
              <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
                Business
              </p>
              <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                What CFR really is about
              </h3>
            </div>
          </a>
          {/** <!-- End Card  --> **/}

          {/** <!-- Card  --> **/}
          <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800" href="#">
            <div className="aspect-w-16 aspect-h-9">
              <img className="w-full object-cover rounded-t-xl" src="https://images.unsplash.com/photo-1668863699009-1e3b4118675d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3387&q=80" alt="Image Description" />
            </div>
            <div className="p-4 md:p-5">
              <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
                Business
              </p>
              <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                Should Product Owners think like entrepreneurs?
              </h3>
            </div>
          </a>
          {/** <!-- End Card  --> **/}

          {/** <!-- Card  --> **/}
          <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800" href="#">
            <div className="aspect-w-16 aspect-h-9">
              <img className="w-full object-cover rounded-t-xl" src="https://images.unsplash.com/photo-1668584054131-d5721c515211?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80" alt="Image Description" />
            </div>
            <div className="p-4 md:p-5">
              <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
                Facilitate
              </p>
              <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                Announcing Front Strategies: Ready-to-use rules
              </h3>
            </div>
          </a>
          {/** <!-- End Card  --> **/}
        </div>
        {/** <!-- End Grid  --> **/}

        {/** <!-- Card  --> **/}
        <div className="text-center">
          <div className="inline-block bg-white border shadow-sm rounded-full dark:bg-slate-900 dark:border-gray-800">
            <div className="py-3 px-4 flex items-center gap-x-2">
              <p className="text-gray-600 dark:text-gray-400">
                Want to read more?
              </p>
              <a className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="../docs/index.html">
                Go here
                <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/** <!-- End Card  --> **/}
      </div>
      {/** <!-- End Card Blog  --> **/}
    </section>
  )
}

interface HoverVideoPlayerProps extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  src: string;
  imagePreviewSrc?: string;
}

const HoverVideoPlayer: React.FC<HoverVideoPlayerProps> = ({ src, imagePreviewSrc, ...props }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPreviewShown, setIsPreviewShown] = useState(!!imagePreviewSrc);

  const handleMouseOver = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
      setIsPreviewShown(false);
    }
  };

  const handleMouseOut = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {!isPlaying && (
        <FiPlayCircle
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '4em',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
          }}
        />
      )}
      {isPreviewShown && (
        <img
          src={imagePreviewSrc}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          className={props?.className || ''}
          alt="video preview"
        />
      )}
      <video
        ref={videoRef}
        src={src}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        {...props}
      />
    </div>
  );
};

interface HoverGifPlayerProps {
  gif: string;
  static: string;
  className?: string;
}

const HoverGifPlayer: React.FC<HoverGifPlayerProps> = ({ gif, static: staticSrc, className }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseOver = () => {
    setIsPlaying(true);
  };

  const handleMouseOut = () => {
    setIsPlaying(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {!isPlaying && (
        <FiPlayCircle
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2em',
            color: 'white',
          }}
        />
      )}
      <img
        className={className}
        src={isPlaying ? gif : staticSrc}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        alt="gif"
      />
    </div>
  );
};

const faqList = [
  {
    "question": "Is DapperGPT free to use?",
    "answer": "DapperGPT includes basic features that are available for free. However, users need to have a valid OpenAI API Key to access the service. OpenAI API Key usage costs are paid directly to OpenAI. DapperGPT also offers additional premium features, which can be unlocked through a one-time purchase or yearly plan if you are a team."
  },
  {
    "question": "How can I obtain an OpenAI API Key?",
    "answer": "You can get an OpenAI API Key by signing up at https://platform.openai.com/signup."
  },
  {
    "question": "Do I need a ChatGPT Plus subscription to use DapperGPT?",
    "answer": "No, a ChatGPT Plus subscription is not required. All you need is an OpenAI API Key. Learn more at https://openai.com/blog/openai-api."
  },
  {
    "question": "What distinguishes DapperGPT from ChatGPT?",
    "answer": "While both DapperGPT and ChatGPT are built on the same underlying technology, DapperGPT provides additional features, such as customization, pinning favorites, a smart search like spotlight, voice-to-text, text-to-voice, AI-Powered Note, and a Chrome Extension for autofill capabilities on popular sites."
  },
  {
    "question": "Are there any limits on the number of chats I can have with DapperGPT?",
    "answer": "There are no restrictions on the number of chats you can have with DapperGPT. The only limitations are your OpenAI API key's limit and your browser storage capacity."
  },
  /* {
    "question": "Can I use DapperGPT on multiple devices?",
    "answer": "Yes, yout can but DapperGPT automatically syncs your chats across all devices."
  }, */
  {
    "question": "How do the premium features of DapperGPT enhance my experience?",
    "answer": "Premium features include more features and cloud sync, generate image and more customization, for yearly / team plan you'll be receive the team plan feature once it's ready. For more information contact us to hi@dappergpt.com."
  },
  {
    "question": "Will I be eligible for future updates after purchasing the premium features?",
    "answer": "Yes, all future updates are included in the purchase."
  },
  /* {
    "question": "Is there a money-back guarantee for DapperGPT?",
    "answer": "We offer a 14-day money-back guarantee. If you are unsatisfied with the product, contact us within 14 days for a full refund."
  }, */
  {
    "question": "How does DapperGPT ensure the security of my OpenAI API Key?",
    "answer": "Your OpenAI API Key will be stored only in your browser by default, we don't store your OpenAI API Key to the cloud unless you opt-in. It is stored securely using industry-standard encryption methods. Strict access controls are implemented, allowing only authorized team members with administrative privileges to access the keys for maintenance or troubleshooting purposes. We also conduct regular security audits and maintain strict data handling policies to ensure your API Key's safety."
  },
  /* {
    "question": "How does DapperGPT handle the storage and usage of chat prompts and responses?",
    "answer": "DapperGPT stores chat prompts and responses to provide users with a curated library of content for reference and to streamline prompt creation. Data is stored on a user-level basis and can be shared with team members at the user's discretion. The stored data is used exclusively for providing value to the user through the Prompt Library and Chat Search History and is not utilized for any other purpose."
  }, */
  {
    "question": "Can I self-host DapperGPT?",
    "answer": "No, self-hosting is not currently supported for DapperGPT. but we will be releasing team feature soon which allow you to assign subdomain or domain to your team."
  }
]

export default LandingPage
